
// Libraries
import * as React from 'react'
import _ from 'lodash'
import qs from 'qs'

// Services
import ApiService from '../../../services/api'

// Context
import NotificationContext from '../../../contexts/notification'

// Components
import PrivateRoute from '../../../components/privateRoute'
import LayoutAdmin from '../../../components/layoutAdmin'
import Seo from '../../../components/seo'
import AdminUsersTableHeader from '../../../components/admin/users/adminUsersTableHeader'
import Pagination from '../../../components/pagination'
import {faSitemap} from '@fortawesome/pro-solid-svg-icons'
import Button from '../../../components/button'
import AdminOrganisationsTable from '../../../components/admin/organisations/adminOrganisationsTable'
import AdminOrganisationView from '../../../components/admin/organisations/adminOrganisationView'
import AdminOrganisationEdit from '../../../components/admin/organisations/adminOrganisationEdit'
import AdminOrganisationConfirmDelete from '../../../components/admin/organisations/adminOrganisationConfirmDelete'

class AdminOrganisationsIndexPage extends React.Component {
	state = {
		activeFilters: {
			'filter': {},
		},
		organisations: [],
		pagination: [],
		emptyMessage: 'Retrieving organisation data ...',
		selectedOrganisation: {},
		modalViewShow: false,
		modalEditShow: false,
		modalConfirmDeleteShow: false,
	}

	static contextType = NotificationContext

	render() {
		const {activeFilters, organisations, pagination, emptyMessage, selectedOrganisation, modalViewShow, modalConfirmDeleteShow, modalEditShow} = this.state

		return <PrivateRoute admin>
			<LayoutAdmin className="page--admin-table page--admin-table-organisations nav-blue-half">
				<Seo title="Organisations Management" />
				<div className="admin-table__wrap">
					<AdminUsersTableHeader
						titleText="Organisations Management" titleIcon={faSitemap}
						searchValue={activeFilters.search} searchHandler={_.debounce(this.handleSearchChange, 500)} />

					<div className="buttons-wrap">
						<Button to="/admin/organisations/new" colorEndeavour>New Organisation</Button>
					</div>

					<Pagination pagination={pagination} handlePaginationClick={this.handlePaginationClick} />

					<AdminOrganisationsTable
						organisations={organisations}
						getOrganisations={this.getOrganisations}
						emptyMessage={emptyMessage}
						handleOpenView={this.handleOpenView} />

					{modalViewShow && <AdminOrganisationView
						organisation={selectedOrganisation}
						handleClose={() => this.setState({modalViewShow: false})}
						handleOpenEdit={this.handleOpenEdit}
						handleConfirmDelete={this.handleConfirmDelete}
					/>}

					{modalEditShow && <AdminOrganisationEdit
						organisation={selectedOrganisation}
						handleClose={() => this.setState({
							modalEditShow: false,
							modalViewShow: true
						})}
						handleEditComplete={this.handleEditComplete} />}

					{modalConfirmDeleteShow && <AdminOrganisationConfirmDelete
						organisation={selectedOrganisation}
						handleClose={() => this.setState({modalConfirmDeleteShow: false})}
						handleConfirm={() => this.handleProcessDelete(selectedOrganisation.id)} />}
				</div>
			</LayoutAdmin>
		</PrivateRoute>
	}

	componentDidMount() {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		const searchParams = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

		Promise.all([
			this.getOrganisations({...activeFilters, ...searchParams}, false)
				.catch(() => addNotification('There was an error fetching the organisations.', 'error'))
		]).then(() => {
			this.setState({
				activeFilters:{...activeFilters, ...searchParams},
				emptyMessage: 'No organisations found, try adjusting your filters'
			})
		})
	}

	getOrganisations = (queryParams) => {
		const apiService = new ApiService()
		queryParams = {...this.state.activeFilters, ...queryParams}
		queryParams['paginate'] = 1
		queryParams['counts'] = ['carers']
		
		return apiService.get('organisations', {}, queryParams).then( (response) => {
			this.setState({
				organisations: response.data.data,
				pagination: response.data,
				activeFilters: queryParams
			})
		}).catch(err => console.error(err))
	}

	handleSearchChange = (searchValue) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		if (searchValue) {
			activeFilters['search'] = searchValue
		} else if (activeFilters['search']) {
			delete activeFilters['search']
		}

		if (activeFilters['page']) {
			delete activeFilters['page']
		}

		this.setState({activeFilters}, () => {
			this.getOrganisations(activeFilters)
				.catch(() => addNotification('There was an error filtering the organisations.', 'error'))
		})
	}

	handlePaginationClick = (page) => {
		const {activeFilters} = this.state
		const {addNotification} = this.context

		activeFilters['page'] = page

		this.setState({activeFilters}, () => {
			this.getUsers(activeFilters)
				.catch(() => addNotification('There was an error filtering the organisations.', 'error'))
		})
	}

	handleOpenView = (organisation_id) => {
		const apiService = new ApiService()
		return apiService.get(`organisations/${organisation_id}`)
			.then( (response) => {
				this.setState({
					selectedOrganisation: response.data,
					modalViewShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleOpenEdit = (organisation_id) => {
		const apiService = new ApiService()
		return apiService.get(`organisations/${organisation_id}`)
			.then( (response) => {
				this.setState({
					selectedOrganisation: response.data,
					modalEditShow: true
				})
			})
			.catch(err => console.error(err))
	}

	handleConfirmDelete = () => {
		this.setState({
			modalConfirmDeleteShow: true
		})
	}

	handleProcessDelete = (organisation_id) => {
		const {addNotification} = this.context
		const apiService = new ApiService()

		apiService.delete(`organisations/${organisation_id}`)
			.then( (response) => {
				addNotification('Organisation deleted successfully.', 'success')
				this.updateStateOrganisations(response.data, true)
				this.setState({
					modalConfirmDeleteShow: false,
					modalViewShow: false
				})
			})
			.catch(err => console.error(err))
	}


	handleEditComplete = (organisation) => {
		this.setState({
			modalEditShow: false,
			modalViewShow: true
		})
		
		this.updateStateSelectedOrganisation(organisation)
		this.updateStateOrganisations(organisation)
	}

	updateStateSelectedOrganisation = (organisation) => {
		this.setState(prevState => ({
			selectedOrganisation: {...prevState.selectedOrganisation, ...organisation}
		}))
	}

	updateStateOrganisations = (organisation, removeOrganisation = false) => {
		const {organisations} = this.state

		// update organisations state
		organisations.forEach((existingOrganisation, key) => {
			if (existingOrganisation.id === organisation.id) {
				if (removeOrganisation) {
					organisations.splice(key, 1)
				}
				else {
					organisations[key] = {...existingOrganisation, ...organisation}
				}
			}
		})

		this.setState({organisations})
	}
}

export default AdminOrganisationsIndexPage
