
// Libraries
import * as React from 'react'
import Classnames from 'classnames'

// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons'

class AdminOrganisationsTableRow extends React.Component {
	render() {
		const {organisation, handleOpenView} = this.props
		let classNames = Classnames([
			'admin-table__row',
		])

		return <tr className={classNames}>
			<td className="admin-table__cell">{organisation.name}</td>
			<td className="admin-table__cell">{organisation.contact_name || "-"}</td>
			<td className="admin-table__cell admin-table__cell--email">{organisation.contact_email ? <a href="mailto:organisation.contact_email">{organisation.contact_email}</a> : "-"}</td>
			<td className="admin-table__cell">{organisation.contact_tel || "-"}</td>
			<td className="admin-table__cell">{organisation.carers_count || '-'}</td>
			<td className="admin-table__cell--actions">
				<button onClick={() => handleOpenView(organisation.id)}>
					<FontAwesomeIcon icon={faArrowRight} title="Edit icon" /><span className="sr-only"> View/Edit</span>
				</button>
			</td>
		</tr>
	}
}

export default AdminOrganisationsTableRow
