
// Libraries
import * as React from 'react'

// Components
import Modal from "../../modal"
import Button from "../../button"

class AdminOrganisationConfirmDelete extends React.Component {
	state = {
		inputValue: '',
		inputError: ''
	}

	render() {
		const { organisation, handleClose, handleConfirm } = this.props

		return <Modal handleClose={ handleClose } modalConfirm>
			<div className="modal-confirm">
				<h2>Remove Organisation?</h2>
				<p>The organisation '{ organisation.name }' will be permanently removed from the system. Click 'Remove' to confirm.</p>
				<ul className="button-wrap">
					<li><Button onClick={ handleClose } hollow colorEndeavour>
						Back
					</Button></li>
					<li><Button onClick={ () => handleConfirm() } colorPomegranate>
						Remove
					</Button></li>
				</ul>
			</div>
		</Modal>
	}
}

export default AdminOrganisationConfirmDelete
