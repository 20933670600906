
// Libraries
import * as React from 'react'
import moment from "moment"

// Services
import ApiService from "../../../services/api"

// Components
import Modal from "../../modal"
import AdminVenueViewRow from "../venues/adminVenueViewRow"
import InputFactory from "../../inputFactory"
import Button from "../../button"

// Context
import NotificationContext from '../../../contexts/notification'

class AdminOrganisationEdit extends React.Component {
	state = {
		inputValues: {
			address: {
				is_primary: 1,
				is_billing: 1,
				is_shipping: 1,
				country_code: 'GB'
			}
		},
		inputErrors: {
			address: {},
		},
		isSubmitting: false
	}

	static contextType = NotificationContext

	render() {
		const { organisation, handleClose } = this.props
		const { inputValues, inputErrors, isSubmitting } = this.state

		const addressErrors = inputErrors ? {
			street: inputErrors['address.street'],
			street_2: inputErrors['address.street_2'],
			state: inputErrors['address.state'],
			postal_code: inputErrors['address.postal_code'],
			city: inputErrors['address.city'],
			region: inputErrors['address.region']
		} : {}

		return <Modal handleClose={ handleClose } modalRight adminForm>
			<div className="admin-form admin-form--organisations">
				<div className="admin-form__header">
					<div className="column">
						<h3>{ organisation.name }</h3>
						<p>Added: { moment(organisation.created).format('DD MMM YYYY') }</p>
					</div>
					<div className="column">
					</div>
				</div>
				<div className="admin-form__form">
					<form onSubmit={ this.handleSubmit } encType="multipart/form-data">
						<div className="admin-form__field-group">
							<h2>Information</h2>
							<AdminVenueViewRow
								label="Organisation Name*"
								value={
									<InputFactory
										type="text"
										name="name"
										placeholder="Name"
										value={ inputValues?.name }
										error={ inputErrors?.name }
										onChange={ this.handleInputChange }
									/>
								} />

							<AdminVenueViewRow
								label="Address*"
								value={
									<InputFactory
										type="address"
										name="address"
										placeholder="Address"
										value={ inputValues?.address }
										error={ addressErrors }
										onChange={ this.handleInputChange }
									/>
								} />

							<AdminVenueViewRow
								checkbox
								label=""
								value={
									<InputFactory
										type="checkbox"
										name="outside_england"
										label={<>
											Is your organisation registered outside of England?<br/>
											<small>If checked, your organisation will be subject to a manual
												eligibility process as per our <a href="/terms" target="_blank">terms of service</a></small>
										</>}
										checked={ inputValues?.outside_england }
										value={ inputValues?.outside_england }
										error={ inputErrors?.outside_england }
										onChange={ this.handleInputChange }
										checkboxEndeavour
									/>
								} />

							{ inputValues?.outside_england !== true &&
								<AdminVenueViewRow
									label="CQC Location ID*"
									value={
										<InputFactory
											type="text"
											name="cqc_location_id"
											placeholder="ID"
											value={ inputValues?.cqc_location_id }
											error={ inputErrors?.cqc_location_id }
											onChange={ this.handleInputChange }
										/>
									} />
							}

							<AdminVenueViewRow
								label="Contact Name*"
								value={
									<InputFactory
										type="text"
										name="contact_name"
										placeholder="Name"
										value={ inputValues?.contact_name }
										error={ inputErrors?.contact_name }
										onChange={ this.handleInputChange }
									/>
								} />

							<AdminVenueViewRow
								label="Contact Telephone Number*"
								value={
									<InputFactory
										type="text"
										placeholder="Telephone"
										name="contact_tel"
										value={ inputValues?.contact_tel }
										error={ inputErrors?.contact_tel }
										onChange={ this.handleInputChange }
									/>
								} />

							<AdminVenueViewRow
								label="Contact Email*"
								value={
									<InputFactory
										type="email"
										name="contact_email"
										placeholder="Email"
										value={ inputValues?.contact_email }
										error={ inputErrors?.contact_email }
										onChange={ this.handleInputChange }
									/>
								} />
						</div>

						<div className="admin-form__submit-wrap">
							<Button type="button" onClick={ handleClose } colorEndeavour hollow>Discard Changes</Button>
							<Button type="submit" isLoading={ isSubmitting } colorEndeavour>Save Changes</Button>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	}

	async componentDidMount() {
		const { organisation } = this.props

		const { inputValues } = this.state

		if (organisation.addresses.length) {
			inputValues.address.street = organisation.addresses[0].street
			inputValues.address.street_2 = organisation.addresses[0].street_2 && organisation.addresses[0].street_2
			inputValues.address.city = organisation.addresses[0].city
			inputValues.address.state = organisation.addresses[0].state
			inputValues.address.postal_code = organisation.addresses[0].postal_code
			inputValues.address.region = organisation.addresses[0].region
		}

		inputValues.outside_england = !organisation.cqc_location_id
		inputValues.name = organisation.name
		inputValues.contact_name = organisation.contact_name
		inputValues.contact_tel = organisation.contact_tel
		inputValues.contact_email = organisation.contact_email

		if (organisation.cqc_location_id) {
			inputValues.cqc_location_id = organisation.cqc_location_id
		} else {
			delete inputValues.cqc_location_id
			inputValues.outside_england = true
		}

		this.setState({ inputValues })
	}

	handleInputChange = (value, name, scope) => {
		const { inputValues } = this.state

		if (name === 'outside_england') {
			delete inputValues['cqc_locaton_id']
		}

		if (value !== undefined) {
			if (scope) {
				inputValues[scope][name] = value
			}
			else {
				inputValues[name] = value
			}
		} else {
			if (scope) {
				delete inputValues[scope][name]
			}
			else {
				delete inputValues[name]
			}
		}

		this.setState({ inputValues })
	}

	handleFileChange = (value, name) => {
		const { inputValues } = this.state
		inputValues[name] = value
		this.setState({ inputValues })
	}

	handleSubmit = async (submitEvent) => {
		submitEvent.preventDefault()
		this.setState({
			isSubmitting: true
		})

		const { inputValues } = this.state
		const { organisation, handleEditComplete } = this.props
		const apiService = new ApiService()
		const { addNotification } = this.context

		// Convert inputValues into a form data object
		const body = new FormData()
		Object.keys(inputValues).forEach(key => {
			if (key === 'address') {
				Object.keys(inputValues[key]).forEach(subKey => {
					body.append(`address[${subKey}]`, inputValues[key][subKey])
				})
			}
			else {
				body.append(key, inputValues[key])
			}
		})

		// Submit venue data
		await apiService.put(`organisations/${organisation.id}`, { body: body }, true)
			.then(async (response) => {
				if (response.success) {
					handleEditComplete(response.data);
					addNotification('Organisation Updated', 'success');
				}
				else {
					this.setState({
						inputErrors: response.errors,
						isSubmitting: false
					})
				}
			})
			.catch(err => console.error(err))
	}
}

export default AdminOrganisationEdit
