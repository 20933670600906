
// Libraries
import * as React from 'react'
import Classnames from "classnames"

// Components
import AdminOrganisationsTableRow from "./adminOrganisationsTableRow"
import TableSort from '../tableSort'

class AdminOrganisationsTable extends React.Component {
	render() {
		const {organisations, emptyMessage, handleOpenView, updateStateOrganisations, getOrganisations} = this.props

		let classNames = Classnames([
			'admin-table admin-table--organisations wrap--scrollx',
		])

		return (
			<div className={classNames}>
				<table className="admin-table__table">
					<thead>
						<tr>
							<th>Name <TableSort fieldName="name" refreshData={getOrganisations}/></th>
							<th>Contact <TableSort fieldName="contact_name" refreshData={getOrganisations}/></th>
							<th>Email</th>
							<th>Telephone</th>
							<th>Carers</th>
							<th className="actions"><span className="sr-only">Actions</span></th>
						</tr>
					</thead>
					<tbody>
						{organisations?.length
							? organisations.map((organisation) => <AdminOrganisationsTableRow
								key={organisation.id}
								organisation={organisation}
								handleOpenView={handleOpenView}
								updateStateOrganisations={updateStateOrganisations} />)
							: <tr><td colSpan={100} align="center">{emptyMessage}</td></tr>}
					</tbody>
				</table>
			</div>
		)
	}
}

export default AdminOrganisationsTable
