
// Libraries
import * as React from 'react'
import moment from 'moment'

// Components
import Modal from '../../modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/pro-solid-svg-icons'
import AdminUserViewRow from '../users/adminUserViewRow'
import Button from '../../button'

class AdminOrganisationView extends React.Component {
	state = {
		openActionMenu: false,
	}

	render() {
		const { organisation, handleClose, handleOpenEdit, handleConfirmDelete } = this.props

		return <Modal handleClose={ handleClose } modalRight>
			<div className="admin-view admin-view--organisation">
				<div className="admin-view__header">
					<div className="column">
						<h2>Organisation 1</h2>
						<h3>{ organisation?.name }</h3>
						<p>Created: { moment(organisation?.created_at).format('DD MMM YYYY') }</p>
					</div>
					<div className="column">
						<ul className='admin-view__header-nav'>
							<li><Button onClick={ () => handleOpenEdit(organisation?.id) } iconOnly>
								<FontAwesomeIcon icon={ faEdit } title="Edit icon" />
								<span className="sr-only">Edit Organisation</span>
							</Button></li>
							{parseInt(organisation?.carers_count) === 0 && <li><Button onClick={ () => handleConfirmDelete(organisation?.id) } iconOnly danger>
								<FontAwesomeIcon icon={ faTrashAlt } title="Delete icon" />
								<span className="sr-only">Delete Organisation</span>
							</Button></li>}
						</ul>
					</div>
				</div>
				<div className="admin-view__view">
					<div className="admin-view__view-group">
						<AdminUserViewRow label="Name" value={ organisation.name } />
						<AdminUserViewRow label="Address Line 1" value={ organisation.addresses.length ? organisation.addresses[0].street : '-' } />
						{ (organisation.addresses.length > 0 && organisation.addresses[0].street_2) &&
							<AdminUserViewRow label="Address Line 2" value={ organisation.addresses[0].street_2 } /> }
						<AdminUserViewRow label="City" value={ organisation.addresses.length ? organisation.addresses[0].city : '-' } />
						<AdminUserViewRow label="County" value={ organisation.addresses.length ? organisation.addresses[0].state : '-' } />
						<AdminUserViewRow label="Postcode" value={ organisation.addresses.length ? organisation.addresses[0].postal_code : '-' } />
						<AdminUserViewRow label="Region" value={ organisation.addresses.length ? organisation.addresses[0].region : '-' } />
					</div>

					<div className="admin-view__view-group">
						<AdminUserViewRow label="Contact Name" value={ organisation?.contact_name || "-" } />
						<AdminUserViewRow label="Contact Email" value={ organisation?.contact_email ? <a href="mailto:organisation.contact_email">{organisation.contact_email}</a> : '-' } />
						<AdminUserViewRow label="Contact Telephone" value={ organisation?.contact_tel || "-" } />
						{ organisation?.cqc_location_id && <AdminUserViewRow label="CQC Location ID" value={ organisation?.cqc_location_id } /> }
						<AdminUserViewRow label="Carers Count" value={ organisation?.carers_count || '-' } />
					</div>
				</div>
			</div>
		</Modal>
	}
}

export default AdminOrganisationView
